import { Component, OnInit } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { BugsnagService } from '../services/bugsnag.service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  mobile: any;
  loader: boolean = false;
  getAuthToken: any;
  getAuthKey: any;
  sessionId: any;

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    this.mobile = this.route.snapshot.queryParams.mobile;
    console.log(this.mobile, 'mobile');
    // this.mobile = '9042447434';
    // console.log(this.mobile, 'mobile')
  }

  ngOnInit(): void {
    let getMobile = sessionStorage.getItem('mobileNo');
    this.getAuthToken = sessionStorage.getItem('authToken');
    this.getAuthKey = sessionStorage.getItem('authKey');
    //sessionStorage.setItem('authKey', res['auth'].key)
    //this.getCustomer();
    //this.redirectUrlApi();
    //this.tokenGenerate();
    this.tokenGet();
  }

  tokenGet() {
    this.spinner.show();
    this.apiService.getWebqloTokenData(this.mobile).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log(res, 'token generate api');
        if (res.status == 'success') {
          let token = res.result.token;
          let key = res.result.key;
          sessionStorage.setItem('authToken', token);
          sessionStorage.setItem('authKey', key);
          sessionStorage.setItem('mobileNo', this.mobile);
          this.getAuthToken = sessionStorage.getItem('authToken');
          this.getAuthKey = sessionStorage.getItem('authKey');
          this.getCustomer();
        } else {
          alert(res.message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err) {
          let errordata = err.status + ' ' + err.statusText;
          alert(errordata);
        }
        this.spinner.hide();
        this.bsService.notifyError(err, {mobile: this.mobile});
        console.log(err, 'error data');
      }
    );
  }

  // tokenGenerate(){
  //     let password = 'ManjuKomme@7'
  //     let formData = {
  //       "identifierType":"MOBILE",
  //       "identifierValue":this.mobile,
  //       "deviceId": env.DEVICE_ID,
  //       "brand": env.BRAND,
  //       "password":password,
  //       "confirmPassword":password
  //     }
  //     this.spinner.show();
  //     this.apiService.tokenGenerate(formData).pipe(first())
  //     .subscribe(res=>{
  //       this.spinner.hide();
  //       if(res['status'].code == 200){
  //         if(res['user'].userRegisteredForPassword == false){
  //           console.log('ususerRegisteredForPassword false ')
  //               alert('User is not registered in care and share')
  //         }
  //         else{

  //           this.sessionId = res['user'].sessionId;
  //           let formData = {
  //             "identifierType":"MOBILE",
  //             "identifierValue":this.mobile,
  //             "deviceId": env.DEVICE_ID,
  //             "brand": env.BRAND,
  //             "sessionId": this.sessionId,
  //             "password":password,
  //           }
  //           this.spinner.show();
  //           this.apiService.passwordValidate(formData).pipe(first())
  //           .subscribe(res =>{
  //             this.spinner.hide();
  //             if(res['status'].code == 200){
  //               sessionStorage.setItem('authToken', res['auth'].token)
  //               sessionStorage.setItem('authKey', res['auth'].key)
  //               if(res['user'].userRegisteredForPassword == true || res['status'].code == 1515){
  //                  sessionStorage.setItem('userPassword', password)
  //                 // Update customer with last login

  //               }
  //             }
  //             else if(res['status'].code == 1528 || res['status'].message == "Password entered is invalid, Please try again"){
  //               console.log('password error 1--')

  //             }
  //             else{
  //               console.log('password error 2--')

  //               return;
  //             }
  //              }) // password validate

  //         }

  //       }// token generate success - 200
  //     }) // token generate
  //   }

  getCustomer() {
    this.spinner.show();
    this.apiService.getCapCustomer(this.mobile, this.getAuthToken).subscribe(
      (res) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == 'block_count';
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            sessionStorage.clear();
            this.router.navigate(['/block-notice']);
          }
          console.log(res, '====== customer Get ==========');
          this.router.navigate(['/carepoints']);
        } else if (
          res['status'].code == 401 &&
          res['status'].message == 'Mobile mismatch.'
        ) {
          alert(res['status'].message);
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err, 'error data');
        console.log(this.mobile, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
        this.bsService.notifyError(err, {mobile: this.mobile});
      }
    );
  }
  //  redirectUrlApi(){
  //   this.loader = true;
  //   this.apiService.redirectUrlApi(this.mobile).subscribe((data: any)=>{
  //     this.loader = false;
  //     console.log(data.result, 'data')
  //     sessionStorage.setItem('authToken', data.result.access_token)
  //     this.router.navigate(['/carepoints']);
  //   },err=>{
  //     this.loader = false;
  //     console.log(err.error.message, 'errr====')
  //   })
  // }
}

// https://loyalty.abbottmalaysia.com.my/dashboard?mobile=122980229
