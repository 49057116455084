import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/_helpers/must-mathc.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import {environment as env} from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-update-mobile',
  templateUrl: './update-mobile.component.html',
  styleUrls: ['./update-mobile.component.scss']
})
export class UpdateMobileComponent implements OnInit {
  changePasswordForm: FormGroup;
  submittedChangePasswordForm = false;
  showChangePasswordForm = true;
  changePasswordOtpForm: FormGroup;
  submittedChangePasswordOtpForm = false;
  showChangePasswordOtpForm = false;
  mobileNo: any
  sessionId: any;
  changePasswordValues : any;
  params: any;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px',
    },
    inputClass: true
  };
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  passworderror: boolean = false;
  oldPassworderror: boolean = false;
  NewMobileNo: any;
  otpLength: any
  changeMob:any;
  @ViewChild('alreadyRegistered', { static: true }) alreadyRegistered: ModalDirective;
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private apiService: ApiService,
    private spinner: NgxSpinnerService, 
    private route: ActivatedRoute, 
    private bsService: BugsnagService
    ) {
      this.changeMob = route.snapshot.params.mobNum;
      console.log(this.changeMob, 'this.changeMob')

      this.mobileNo = sessionStorage.getItem('mobileNo');
      console.log(this.mobileNo, 'this.mobileNo')
     }
     

    onOtpChange(otp) {
      // this.otp = otp;
      this.invalidOtp = false
      this.otp = otp;
      console.log('---', otp.length);
      this.otpLength = otp.length
      if (otp.length == 6) {
        this.activeClassButton = true;
      } else {
        this.activeClassButton = false;
      }
    }
    
    setVal(val) {
      this.ngOtpInput.setValue(val);
    }

    onClickChangeNumber() {
      this.showChangePasswordOtpForm = false;
      this.router.navigate(['/profile']);
      // this.showChangePasswordForm = true;
    }
  ngOnInit(): void {
    window.scrollTo({top:0});
    this.showChangePasswordForm = true;
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.pattern('^(?=.*[!@#$%^&*?_])(?=.*[0-9]).{8,}$')]],
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.*[!@#$%^&*?_])(?=.*[0-9]).{8,}$')]],
      confirmNewPassword: ['', [Validators.required, Validators.pattern('^(?=.*[!@#$%^&*?_])(?=.*[0-9]).{8,}$')]] 
    },
    {
      validator:  MustMatch('newPassword','confirmNewPassword')
    })
    this.changePasswordOtpForm = this.formBuilder.group({
      otp: ['',  [Validators.required,  Validators.pattern("[0-9]{1,6}$"), Validators.minLength(6), Validators.maxLength(6)]]
    })
  }

  get changePasswordFval() {
    return this.changePasswordForm.controls;
  }
  onSubmitChangePasswordForm() {
    this.submittedChangePasswordForm = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    else {

      let userpasswd = sessionStorage.getItem('userPassword');
      console.log(userpasswd, 'userpasswd--')
      this.changePasswordValues = this.changePasswordForm.value

      
    if(userpasswd != this.changePasswordValues.currentPassword){      
        this.passworderror = true;
        console.log('inside')
        if(userpasswd == this.changePasswordValues.newPassword
          && userpasswd == this.changePasswordValues.confirmNewPassword){
            this.oldPassworderror = true;
            return;
        }
        else{ this.oldPassworderror = false; }
        return;
      }
      else{ this.passworderror = false; }

    
      if(userpasswd == this.changePasswordValues.newPassword
        && userpasswd == this.changePasswordValues.confirmNewPassword){
          this.oldPassworderror = true;
          if(userpasswd != this.changePasswordValues.currentPassword){      
          this.passworderror = true;
          console.log('inside')
          return;
        }
        else { this.passworderror = false; }
        return;
      }
      else { this.oldPassworderror = false; }


      this.showChangePasswordForm = false;
      this.showChangePasswordOtpForm = true;
      this.beginTimer = true;
  
    }
  }

  get changePasswordOtpFval() {
    return this.changePasswordOtpForm.controls;
  }



  onSubmitChangePasswordOtpForm(){
   this.submittedChangePasswordOtpForm = true;
   if(this.otpLength != 6){
     return
   }
   else {
    let newToken = sessionStorage.getItem('authToken')

    let formData = {
      "identifierType":"MOBILE",
      "identifierValue": this.changeMob,
      "deviceId": env.DEVICE_ID,
      "brand": env.BRAND,
      "password": 'test123',
      "confirmPassword": 'test123',
    }

    this.spinner.show();
    this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
    .subscribe(res=>{
      this.spinner.hide();
      if(res['status'].code == 200){
        if(res['user'].userRegisteredForPassword == true){
          this.showRegisterModal();
          console.log('user(number) already registered')
        }
        else{

          if(this.changeMob != null){
            let updateMobile = {
            "root": {
              "customer": [
              {
                 "identifier": 'mobile',
                 "old_value": '60' + this.mobileNo,
                 "new_value": '60' + this.changeMob
              }
              ]
           }
          }
      
          console.log(updateMobile, 'updateMobile')
          this.spinner.show();
            this.apiService.updateCapCustomerIdentity(updateMobile, this.mobileNo, newToken).pipe(first())
            .subscribe(res=>{
              if(res['status'].code == 200){
              console.log(res, '=== updated mobile ===')
      
      
             let formData = {
              "identifierType":"MOBILE",
              "identifierValue": this.changeMob,
              "deviceId": env.DEVICE_ID,
              "brand": env.BRAND,
              "password": 'test123',
              "confirmPassword": 'test123',
            }
            this.spinner.show();
            this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
            .subscribe(res=>{
              this.spinner.hide();
              if(res['status'].code == 200){
                  this.sessionId = res['user'].sessionId;
                  sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
                  let token = sessionStorage.getItem('authToken')
                      let otpGenerateData = {
                        "identifierType":"MOBILE",
                        "identifierValue": this.changeMob,
                        "deviceId": env.DEVICE_ID,
                        "brand" : env.BRAND,
                        "sessionId": this.sessionId
                    }      
                    this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
                    .subscribe(res=>{
                      this.spinner.hide();
                        if(res['status'].code == 200){
               
                          let formValues = this.changePasswordOtpForm.value;
                          console.log('inside else---')
                          let otpSubmitData = {
                            "identifierType":"MOBILE",
                            "identifierValue": this.changeMob,
                            "deviceId": env.DEVICE_ID,
                            "brand" : env.BRAND,
                            "sessionId": this.sessionId,
                            "otp": this.otp
                          }
                          this.spinner.show();
                          this.apiService.otpValidate(otpSubmitData).pipe(first())
                          .subscribe(res=>{
                            this.spinner.hide();
                            if(res['status'].code == 200){
                             sessionStorage.setItem('authToken', res['auth'].token);
                             sessionStorage.setItem('authKey', res['auth'].key)

                              let getauthkey = sessionStorage.getItem('authKey')
                              let newToken = sessionStorage.getItem('authToken')
                              let getMobile = this.changeMob
                                    // OTP Form Data
                                  this.spinner.show();
                                  let OTPformData = {
                                    mobile: getMobile,
                                    otp_token: newToken,
                                    otp_key: getauthkey
                                  }
                                  this.apiService.postAuthToken(OTPformData).subscribe(res=>{
                                    this.spinner.hide();
                                    console.log(res, 'otp form data submit')
                                  },err=>{
                                    this.spinner.hide();
                                    this.bsService.notifyError(err, OTPformData);
                                  })
                             
                             let formData =
                             {
                               "identifierType":"MOBILE",
                               "identifierValue": this.changeMob,
                                 "deviceId": env.DEVICE_ID,
                                 "brand": env.BRAND,
                                 "password": 'test123',
                                 "newPassword": this.changePasswordValues.newPassword,
                                 "confirmPassword": this.changePasswordValues.confirmNewPassword,
                                 "token": newToken
                         
                             }
                       console.log(formValues, 'reset passwd form')
                        this.spinner.show();
                        this.apiService.changePassword(formData).pipe(first())
                        .subscribe(res =>{
                          this.spinner.hide();
                          if(res['status'].code == 200){
                            let userpasswd = sessionStorage.setItem('userPassword', this.changePasswordValues.confirmNewPassword); 
                             sessionStorage.setItem('mobileNo', this.changeMob)         
                              this.router.navigate(['/profile']);
                          }
                          else{
                            console.log(res['status'].message)
                          }
                        }, err=>{
                          this.spinner.hide();
                          console.log(err)
                          this.bsService.notifyError(err, formData);
                        })
               
                     }
                            else{
                              console.log('invalid otp')
                              this.invalidOtp = true;
                            }
                            },err=>{
                              console.log(err)
                              this.bsService.notifyError(err, otpSubmitData);
                            })
               
               
                        } 
                        else{ // otp genearte else
                          //this.toastr.error(res['status'].message)
                        }
                      },err=> { // otp genearte error
                        this.spinner.hide();
                        console.log(err, '=== OTP gererate error list =======')
                        this.bsService.notifyError(err, otpGenerateData);
                    }); 
      
                    
                }
                else{
      
                }
      
              }, err => {
                this.bsService.notifyError(err, formData);
              })
      
            
              this.spinner.hide();
              }else{
                console.log(res['status'].message);
              }
            },err=>{
              this.spinner.hide();
              console.log(err, 'err===')
              this.bsService.notifyError(err, formData);
            })
          } 
          

        }
      }
      })

   }
}


  handleEvent(event) {
    console.log(event.left, 'event')
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
}

resendOTP(){
  this.spinner.show();
  this.showResendOtp = false;
  let getMobile = sessionStorage.getItem('mobileNo')
  let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
  let otpGenerateData = {
    "mobile": getMobile,
    "deviceId": env.DEVICE_ID,
    "brand" : env.BRAND,
    "sessionId": sessionIdValue
  }
  this.apiService.otpGenerate(otpGenerateData).pipe(first())
  .subscribe(res=>{
    this.spinner.hide();
      if(res['status'].code == 200){
        
      } 
      else if(res['status'].code == 401){
        this.apiService.logout()
      }
      else{
         this.apiService.showToaster(res['status'].message)
      }
    },err=> {
      this.spinner.hide();
      console.log(err, '=== OTP gererate error list =======')
      this.bsService.notifyError(err, otpGenerateData);
  });
}

otpInput(ev){
  if(ev.keyCode == 8){
    this.invalidOtp = false;
  }
}

confirmPasswordError(ev){
  if(ev.keyCode == 8){
    this.oldPassworderror = false;
  }
}

passwordError(ev){
  if(ev.keyCode == 8){
    this.passworderror = false;
  }
}

closeRegisterModal(){
  this.alreadyRegistered.hide();
  this.router.navigate(['/profile']);
}

showRegisterModal(){
  this.alreadyRegistered.show();
}

ngOnDestroy(){
  sessionStorage.removeItem('NewMobileNo');
}

}
