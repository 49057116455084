// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  WRAPPER_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  BRAND: 'ABBOTTALLDEMO',
  DEVICE_ID: '123454321',
  IDENTIFIER_TYPE: 'MOBILE',
  // TILL_ID:"abbottphtemppage",
  TILL_ID: 'abbottdemomalaysia',

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  STTARTER_TERMS_FAQ:
    'https://api.sttarter.com/contentsystem/62b8b17263017ed475b3bfe42ec6a2b2',

  auth_key: 'WLQ7eRcvLNSb0Z3hSAYIswPuf',
  auth_secret: 'FfuXJFW5nW7zovCVVnDCmLF2TV7sUd',
  webqloURL: 'https://pediasure-growth-staging.uidhosting.com',

  webQlo_api_key: 'kFhq5xcXzsHJrqMoptDdqsfNt',
  webQlo_api_secret: 'gkKcn642fEWggagJBjulbHjmjz3VED18RSYUDgnD',
  COUNTRY_CODE: 'MY',
  LANGUAGE: 'en_US',
  REWARD_ID1: '1707',
  REWARD_ID2: '1706',
  SITE_KEY: "6LcV2mQbAAAAALcsfVCxf7zChESp1v-1WOT3JOFB",
  bugsnag_version: '23092022'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
