import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/_helpers/must-mathc.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import {environment as env} from '../../../environments/environment';
import { first } from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotMobileForm: FormGroup;
  submittedMobileForm =  false;
  showForgotMobileForm = true;
  submittedResetForm =  false;
  resetForm: FormGroup;
  showResetForm = false;
  forgotOtpForm: FormGroup;
  submittedOtpForm = false;
  showOtpForm = false;
  sessionId: any;
  userNotFound: boolean = false;
  mobileNo: any;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px',
    },
    inputClass: true
  };
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  otpLength: any;
  nonRegisteredUser: boolean = false;
  passwordError: boolean = false;
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private apiService: ApiService,
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) { }
  



    onOtpChange(otp) {
      // this.otp = otp;
      this.invalidOtp = false
      this.otp = otp;
      console.log('---', otp.length);
      this.otpLength = otp.length
      if (otp.length == 6) {
        this.activeClassButton = true;
      } else {
        this.activeClassButton = false;
      }
    }
    
    setVal(val) {
      this.ngOtpInput.setValue(val);
    }
  ngOnInit(): void {
    window.scrollTo({top:0})
    this.showForgotMobileForm = true;
    // Validators.pattern("^((\\+63-?)|0)?[0-9]{9,10}$")
    this.forgotMobileForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.minLength(9),
      Validators.maxLength(10)]],
      countryCode: ['']
    })

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[!@#$%^&*?_])(?=.*[0-9]).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[!@#$%^&*?_])(?=.*[0-9]).{8,}$')]]
    },
    {
      validator:  MustMatch('password', 'confirmPassword')
    })

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', [Validators.required,  Validators.pattern("[0-9]{1,6}$"), Validators.minLength(6), Validators.maxLength(6)]]
    })
  }

  get ForgotMobileFval() {
    return this.forgotMobileForm.controls;
  }
  onForgotMobileFormSubmit() {
    this.submittedMobileForm = true;
    if (this.forgotMobileForm.invalid) {
      return;
    }
    else {
      let formValues = this.forgotMobileForm.value
      this.mobileNo = formValues.mobileNumber

      let formData = {
        "identifierType":"MOBILE",
        "identifierValue":this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
      }
     
      this.spinner.show();
      this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
      .subscribe(res=>{
        
        this.spinner.hide();
        if(res['status'].code == 200){
          if(res['user'].userRegisteredForPassword == false){
            this.userNotFound = true;
            console.log('user not registered')
          }
          else{
            this.userNotFound = false;
            this.nonRegisteredUser = false;
            this.sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
            this.showForgotMobileForm = false;
            this.showResetForm = true;
            this.forgotMobileForm.reset();
            this.submittedMobileForm = false;
  
          }

          }
          else if(res['status'].code == 1520){
            console.log('test')
            this.nonRegisteredUser = true;
          }
          else{}

        }, err => {
          this.bsService.notifyError(err, formData);
        })

    }
  }

  get resetFval() {
    return this.resetForm.controls;
  }
  onSubmitResetForm() {
    this.submittedResetForm = true;
    if (this.resetForm.invalid) {
      return;
    }
    else {

      let formValues = this.resetForm.value
      this.spinner.show();
        this.sessionId = JSON.parse(sessionStorage.getItem('sessionID'))
                   // //----------forgot password---------
                   let formData = {
                    "identifierType":"MOBILE",
                    "identifierValue":this.mobileNo,
                    "deviceId": env.DEVICE_ID,
                    "brand": env.BRAND,
                    "sessionId": this.sessionId,
                    "password":formValues.password,
                    "confirmPassword": formValues.confirmPassword
                  }
                 
                  this.apiService.forgotPassword(formData).pipe(first())  // forgot password api
                  .subscribe(res =>{
                   
                    this.spinner.hide();
                    if(res['status'].code == 200){
                      this.passwordError = false;
                      this.showResetForm = false;
                      this.showOtpForm = true;
                      this.beginTimer = true;

                      this.resetForm.reset();
                      this.submittedResetForm = false;

                      let otpformValue = this.forgotOtpForm.value

                      let otpGenerateData = {
                        "identifierType":"MOBILE",
                          "identifierValue":this.mobileNo,
                          "deviceId": env.DEVICE_ID,
                          "brand" : env.BRAND,
                          "sessionId": this.sessionId
                      }     
                     
                      this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
                      .subscribe(res=>{
                        
                        this.spinner.hide();
                          if(res['status'].code == 200){
                
                            let formValues = this.forgotOtpForm.value;
                            console.log('inside else---')
                            console.log(this.forgotOtpForm.value.otp, 'this.forgotOtpForm.value.otp--')
                
                
                
                          } 
                          else{ // otp genearte else
                            //this.toastr.error(res['status'].message)
                          }
                        },err=> { // otp genearte error
                          this.spinner.hide();
                          console.log(err, '=== OTP gererate error list =======')
                          this.bsService.notifyError(err, otpGenerateData);
                      }); 
                    }
                    else if(res['status'].code == 1527){
                      this.passwordError = true;
                    }
                    else{ // forgot passsword else
                      console.log(res['status'].message)
                    }
                  },err=>{ //forgot passsword error
                    this.spinner.hide();
                    console.log(err)
                    this.bsService.notifyError(err, formData);
                  })

    }
  }

  get forgotOtpFval() {
    return this.forgotOtpForm.controls;
  }

  onSubmitForgotOtpForm() {
    this.submittedOtpForm = true;
    
    // if (this.forgotOtpForm.invalid) {
    //   return;
    // }
     if ( this.otpLength != 6) {
      return;
    }   
   else {
      // this.router.navigate(['/login'])

      let otpSubmitData = {
        "identifierType":"MOBILE",
        "identifierValue":this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand" : env.BRAND,
        "sessionId": this.sessionId,
        "otp": this.otp
      }
     
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
      .subscribe(res=>{
        
        this.spinner.hide();
        if(res['status'].code == 200){
          sessionStorage.setItem('authToken', res['auth'].token)
          sessionStorage.setItem('authKey', res['auth'].key)
         let getauthtoken = sessionStorage.getItem('authToken')
         let gethauthkey = sessionStorage.getItem('authKey')
          let getMobile = this.mobileNo
          // OTP Form Data
          this.spinner.show();
          let OTPformData = {
            mobile: getMobile,
            otp_token: getauthtoken,
            otp_key: gethauthkey
          }
          this.apiService.postAuthToken(OTPformData).subscribe(res=>{
            this.spinner.hide();
            console.log(res, 'otp form data submit')
          },err=>{
            this.spinner.hide();
            this.bsService.notifyError(err, OTPformData);
          })
          this.router.navigateByUrl('/login')
         
          this.forgotOtpForm.reset();

          this.showOtpForm = false;
          this.beginTimer = false;
          this.router.navigate(['/login'])
        }
        else{
          this.invalidOtp = true;
        }
        },err=>{
          console.log(err)
          this.bsService.notifyError(err, otpSubmitData);
        })
    }
  }

  handleEvent(event) {
    console.log(event.left, 'event')
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  otpInput(ev){
    if(ev.keyCode == 8){
      this.invalidOtp = false;
    }
  }

  onClickChangeNumber() {
    this.showOtpForm = false;
    this.showForgotMobileForm = true;
  }

  resendOTP(){
    this.spinner.show();
    this.showResendOtp = false;
    let getMobile = sessionStorage.getItem('mobileNo')
    let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    let otpGenerateData = {
      "mobile": getMobile,
      "deviceId": env.DEVICE_ID,
      "brand" : env.BRAND,
      "sessionId": sessionIdValue
    }
    
    this.apiService.otpGenerate(otpGenerateData).pipe(first())
    .subscribe(res=>{
      
      this.spinner.hide();
        if(res['status'].code == 200){
          
        } 
        else if(res['status'].code == 401){
          this.apiService.logout()
        }
        else{
           this.apiService.showToaster(res['status'].message)
        }
      },err=> {
        this.spinner.hide();
        console.log(err, '=== OTP gererate error list =======')
        this.bsService.notifyError(err, otpGenerateData);
    });
  }

  onlyNumbers(ev){
    let onlynum = /^[0-9]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  mobileInput(ev){
    if(ev.keyCode == 8){
      this.userNotFound = false;
      this.nonRegisteredUser = false;
    }
  }

  passwordInput(ev){
    if(ev.keyCode == 8){
      this.passwordError = false;
    }
  }

}
